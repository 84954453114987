import { template as template_6349cc4c6fef4ee2a7abbc4c9fb1a9c5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_6349cc4c6fef4ee2a7abbc4c9fb1a9c5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
