import { template as template_46b006851abd44269d6b68439217d5a4 } from "@ember/template-compiler";
const WelcomeHeader = template_46b006851abd44269d6b68439217d5a4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
