import { template as template_81124ea62fdc44a4a13e8f9ffbc2fe62 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_81124ea62fdc44a4a13e8f9ffbc2fe62(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
