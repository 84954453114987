import { template as template_a8bdf56293f749b2a51e9c16e536a717 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a8bdf56293f749b2a51e9c16e536a717(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
